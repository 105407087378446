<template>
  <div class="page-container financial-statements-page">
    <portal to="page-name">Extrato</portal>
    <div class="card card-page">
      <div class="card-header">
        <button class="btn btn-primary btn-icon-left float-right"
                :disabled="!filter.bankAccountId"
                @click="openBankReconciliation">
          <fa-icon :icon="['fal', 'coins']"></fa-icon>Conciliação bancária
        </button>
        <h1 class="card-title">Extrato</h1>
      </div>
      <div class="card-body">
        <div class="columns">
          <div class="column col-auto col-md-6 col-sm-12 form-group">
            <label class="form-label">Conta</label>
            <select class="form-select" v-model="filter.bankAccountId">
              <option value="">[Selecione uma conta]</option>
              <option v-for="item in bankAccounts"
                      :value="item.id" :key="item.id">{{ item.name }}</option>
            </select>
          </div>
          <div class="column col-auto col-md-6 col-sm-12 form-group">
            <label class="form-label">Período</label>
            <select class="form-select" v-model="filter.period" @change="setDate">
              <option value="today">Hoje</option>
              <option value="week">Esta semana</option>
              <option value="month">Este mês</option>
              <option value="30">últimos 30 dias</option>
              <option value="60">últimos 60 dias</option>
              <option value="custom">Escolha um período</option>
            </select>
          </div>
          <div class="column col-2 col-md-6 col-sm-12 form-group"
               :class="{'has-error': $v.filter.startDate.$error}">
            <label class="form-label">Data inicial</label>
            <dx-input-date type="text" class="form-input"
                           :disabled="filter.period !== 'custom'"
                           v-model="filter.startDate"
                           @blur="$v.filter.startDate.$touch()"
            />
          </div>
          <div class="column col-2 col-md-6 col-sm-12 form-group"
               :class="{'has-error': $v.filter.endDate.$error}">
            <label class="form-label">Data final</label>
            <dx-input-date type="text" class="form-input"
                           :disabled="filter.period !== 'custom'"
                           v-model="filter.endDate"
                           @blur="$v.filter.endDate.$touch()"
            />
          </div>
          <div class="column col-md-12 col-sm-12 form-group">
            <label class="form-label">Pesquisar</label>
            <input type="text" class="form-input"
                   v-model="filter.search"
                   placeholder="Nome ou documento...">
          </div>
        </div>
        <div class="loading loading-lg" v-if="loading" />
        <template v-else>
          <div class="empty mt-2" v-if="data.items.length === 0">
            <div class="empty-icon">
              <fa-icon :icon="['fal', 'info-circle']" size="3x"/>
            </div>
            <p class="empty-title h5">Extrato</p>
            <p class="empty-subtitle">
              Nenhuma movimentação encontrada. Verifique os filtros para realizar novas buscas
            </p>
          </div>
          <table class="table table-striped table-hover" v-else>
            <thead>
            <tr>
              <th style="width: 80px">Data</th>
              <th class="text-center" style="width: 70px">Status</th>
              <th>Lançamento</th>
              <th>Categoria</th>
              <th class="text-right">Valor</th>
              <th class="text-right">Saldo</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(item, i) in data.items" :key="i">
              <td>{{ item.date | date }}</td>
              <td class="text-center">
                <span class="tooltip text-success"
                      v-if="item.reconciliation"
                      data-tooltip="Conciliado">
                  <fa-icon :icon="['fal', 'lock']" />
                </span>
                <span class="tooltip text-error" v-else
                      data-tooltip="Não conciliado">
                  <fa-icon :icon="['fal', 'lock-open']" />
                </span>
              </td>
              <td>{{ item.entity.name }}</td>
              <td>{{ item.category.name }}</td>
              <td class="text-right"
                  :class="item.total < 0 ? 'text-error' : 'text-success'">
                {{ item.total | currency }}
              </td>
              <td class="text-right">{{ item.balance | currency }}</td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
      <div class="card-footer text-right">
        <div class="mr-1">
          <div class="form-group">
            Saldo inicial:
            <span class="text-info">{{ bankAccountBalance | currency }}</span>
          </div>
          <div class="form-group">
            Total de recebimentos:
            <span class="text-success">{{ total.revenueValue | currency }}</span>
          </div>
          <div class="form-group">
            Total de pagamentos:
            <span class="text-error">{{ total.expenseValue | currency }}</span>
          </div>
          <div class="form-group">
            Saldo final: <strong>{{ total.finalValue | currency }}</strong>
          </div>
        </div>
      </div>
    </div>
    <dx-modal
      v-model="bankReconciliation.show"
      title="Conciliação bancária"
      id="bank-reconciliation-modal"
      size="lg">
      <div class="columns">
        <div class="column col-12 form-group">
          <label class="form-label">Conta bancária</label>
          <input type="text" class="form-input text-bold"
                 v-model="bankReconciliation.bankAccountName" readonly>
        </div>
        <div class="column col-12 form-group">
          <div class="upload-area"
               :class="{dragging: bankReconciliation.upload.dragging}">
            <input type="file" class="input-file" multiple="multiple"
                   @dragenter="bankReconciliation.upload.dragging = true"
                   @dragleave="bankReconciliation.upload.dragging = false"
                   @drop="bankReconciliation.upload.dragging = false"
                   @change="uploadChange">
            <div class="upload-inner">
              <fa-icon :icon="['fal', 'upload']"></fa-icon>
              <p>
                Arraste o arquivo do banco aqui
              </p>
            </div>
          </div>
        </div>
<!--        <table class="table table-striped">-->
<!--          <thead>-->
<!--          <tr>-->
<!--            <th>Data</th>-->
<!--            <th>Lançamento</th>-->
<!--            <th class="text-right">Valor</th>-->
<!--          </tr>-->
<!--          </thead>-->
<!--          <tbody>-->
<!--          <tr>-->
<!--            <td>23/03/2022</td>-->
<!--            <td>Descrição do lançamento</td>-->
<!--            <td class="text-right">R$100,00</td>-->
<!--          </tr>-->
<!--          </tbody>-->
<!--        </table>-->
      </div>
      <template slot="footer">
        <button class="btn" @click="bankReconciliation.show = false">Sair</button>
      </template>
    </dx-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { required } from 'vuelidate/lib/validators';
import { date, minDate } from '../../../../data/validators';

export default {
  data() {
    return {
      path: '/financial-statements',
      loading: false,
      bankAccounts: [],
      bankAccountBalance: 0,
      filter: {
        bankAccountId: '',
        period: 'today',
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        search: '',
      },
      data: {
        items: [],
        total: 0,
        limit: 30,
        offset: 0,
      },
      total: {
        revenueValue: 0,
        expenseValue: 0,
        finalValue: 0,
      },
      bankReconciliation: {
        bankAccountName: '',
        show: false,
        upload: {
          files: [],
          dragging: false,
        },
      },
    };
  },
  mounted() {
    this.loadBankAccounts();
  },
  watch: {
    filter: {
      handler() {
        clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(this.load, 800);
      },
      deep: true,
    },
  },
  validations() {
    const rules = {
      filter: {
        bankAccountId: { required },
        startDate: { required, date },
        endDate: { required, date },
      },
    };

    if (this.filter.startDate) {
      rules.filter.endDate = {
        required,
        date,
        minDate: minDate(this.filter.startDate),
      };
    }

    return rules;
  },
  methods: {
    loadBankAccounts() {
      const params = {
        active: true,
        limit: 0,
        cashier: false,
      };
      return this.$http.get('/bank-accounts', { params })
        .then(({ data }) => {
          this.bankAccounts = data.items;
        })
        .catch(() => {
        });
    },
    openBankReconciliation() {
      const bankAccount = this.bankAccounts
        .find(({ id }) => id === this.filter.bankAccountId);
      this.bankReconciliation.bankAccountName = bankAccount.name;
      this.bankReconciliation.show = true;
    },
    uploadChange(e) {
      e.preventDefault();
      if (e.target.files.length) {
        this.bankReconciliation.upload.files = e.target.files;
      } else {
        this.bankReconciliation.upload.files = [];
      }
    },
    calcTotal() {
      this.total.revenueValue = this.data.items
        .filter(({ type }) => type === 'revenue')
        .reduce((a, b) => a + b.total, 0);
      this.total.expenseValue = this.data.items
        .filter(({ type }) => type === 'expense')
        .reduce((a, b) => a + b.total, 0);
      this.total.finalValue = this.data.items.length > 0
        ? this.data.items[this.data.items.length - 1].balance
        : this.bankAccountBalance;
    },
    async load() {
      this.$v.filter.$touch();
      if (this.$v.filter.$error) {
        return;
      }

      this.loading = true;

      const params = {
        startDate: this.filter.startDate,
        endDate: this.filter.endDate,
        bankAccountId: this.filter.bankAccountId,
      };

      if (this.filter.search) {
        params.search = this.filter.search;
      }

      await this.getAccountInitialValue(params);

      this.$http.get(this.path, { params })
        .then(({ data }) => {
          this.data = data;
          let currentBalance = this.bankAccountBalance;
          this.data.items = this.data.items.map((item) => {
            currentBalance += item.total;
            item.balance = currentBalance;
            item.reconciliation = item.reconciliation || false;
            return item;
          });
        })
        .catch(() => {
        })
        .finally(() => {
          this.calcTotal();
          this.loading = false;
        });
    },
    getAccountInitialValue(item) {
      const params = {
        date: item.startDate,
      };

      if (item.bankAccountId) {
        params.id = item.bankAccountId;
      }

      return this.$http
        .get('/bank-account-balance', { params })
        .then(({ data }) => {
          this.bankAccountBalance = data && data.balance ? data.balance : 0;
        })
        .catch()
        .finally();
    },
    setDate() {
      const format = 'YYYY-MM-DD';
      if (this.filter.period === 'today') {
        this.filter.startDate = moment().format(format);
        this.filter.endDate = moment().format(format);
      }
      if (this.filter.period === 'week') {
        this.filter.startDate = moment().startOf('week').format(format);
        this.filter.endDate = moment().endOf('week').format(format);
      }
      if (this.filter.period === 'month') {
        this.filter.startDate = moment().startOf('month').format(format);
        this.filter.endDate = moment().endOf('month').format(format);
      }
      if (['30', '60'].includes(this.filter.period)) {
        this.filter.startDate = moment()
          .subtract(this.filter.period - 1, 'days')
          .format(format);
        this.filter.endDate = moment().format(format);
      }
    },
  },
};
</script>

<style lang="scss">
@import "./src/assets/scss/_variables.scss";

#bank-reconciliation-modal {
  .upload-area {
    align-items: center;
    background-color: lighten($gray-color-light, 5%);
    border: lighten($gray-color, 5%) $unit-h dashed;
    display: flex;
    height: 4rem;
    justify-content: center;
    margin-top: $layout-spacing;
    position: relative;
    &.dragging {
      border-color: $primary-color;
    }
    .input-file {
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
    .upload-inner {
      display: flex;
      align-items: center;
    }
    svg {
      font-size: 1rem;
    }
    p {
      margin: 0 0 0 $layout-spacing;
      text-align: center;
    }
  }
}
</style>
